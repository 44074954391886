$(function() {
	'use strict';

	$(function(){
	  $('a.link-fade').on("click",function() {
	    var url = $(this).attr('href');
	    if (url != '') {
	      $('body').addClass('fade-out');
	      setTimeout(function(){
	        location.href = url;
	      }, 400);
	    }
	    return false;
	  });
	});
	
	window.onpageshow = function(event) {
		if (event.persisted) {
			 window.location.reload();
		}
	};
	

	(function () {
	  
    $('a[href^="#"]:not([href="#"])').click(function () {
      var target, targetOffset;

      jQuery.easing.quart = function (x, t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
      };
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        target = $(this.hash);
        target = (target.length && target) || $('[name="' + this.hash.slice(1) + '"]');
        if (target.length) {
          targetOffset = target.offset().top;
          $('html,body').animate({ scrollTop: targetOffset }, 'normal', 'quart');
          return false;
        }
      }
    });
  }());

});